// 党建工作
<template>
  <div class="partyCulture-partyWork">
    <section class="partyWork-carousel justify-between">
      <!-- 轮播 -->
      <div class="partyWork-image justify-between">
        <img class="partyWork-bigImg" :src="imgUrl" />
        <div class="partyWork-swiper">
          <div class="swiper-button button-prev" @click="topClick()">
            <el-image :src="require('@/assets/images/arrow-top.png')"></el-image>
          </div>
          <el-row class="partyWork-list">
            <el-col class="partyWork-item" :span="24" v-for="(item,index) in data" :key="index"
              @click.native="previewImg(index)">
              <img class="wh100" :src="item" alt="">
            </el-col>
          </el-row>
          <div class="swiper-button button-next" @click="bottomClick()">
            <el-image :src="require('@/assets/images/arrow-bottom.png')"></el-image>
          </div>
        </div>
      </div>
      <div class="partyWork-name">
        <el-image :src="require('../../assets/images/djwh-img2.png')"></el-image>
        <p class="name-title">支部书记：丁坚</p>
      </div>
    </section>
    <!-- 党支部介绍 -->
    <section class="partyWork-info">
      <Info v-if="isData.length" :isData="isData" />
    </section>
    <!-- 支部动态 -->
    <section class="partyWork-dynamic back-border">
      <h2 class="dynamic-title">支部动态</h2>
      <el-row class="dynamic-list">
        <el-col class="dynamic-item back-border " :span="24" v-for="item in news" :key="item.id">
          <router-link class="justify-between"
            :to="{path:'/partyCultureDetails',query:{type:'branch-news',id:item.id}}">
            <p class="dynamic-name align-center">
              <span class="text-ellipsis">{{item.biaoti}}</span>
              <i></i>
            </p>
            <span class="dynamic-time">{{item.created_at|filterTime}}</span>
          </router-link>
        </el-col>
      </el-row>
      <Paginations v-if="nums" :nums='nums' @changePage="changePage" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getInfo, getNews } from '@/api'
import Info from '@/components/Info'
import Paginations from '@/components/Paginations'
import { throttle } from '@/utils/utils'
export default {
  components: {
    Info,
    Paginations
  },
  props: {

  },
  data () {
    return {
      data: [], // 轮播图总数据
      imgUrl: '', // 点击轮播的某项图片路径
      isData: [],
      news: [], // 新闻数据
      nums: 0, // 总页数
    }
  },
  computed: {
    ...mapGetters(['getNavItem'])
  },
  mounted () {
    this.getData()
    this.getNews('branch-news', 1)

  },
  methods: {
    async getData () {
      if (this.getNavItem) {
        let { data } = await getInfo(this.getNavItem.id)
        this.isData = data
        let dataArr = JSON.parse(data[0].logo)
        for (let i = 0; i < dataArr.length; i++) {
          this.data.push(this.$base + dataArr[i])
        }
        // 取后四位 补到数组前四个，确保默认展示的图片在中间
        let arr = this.data.splice(-4, 4)
        this.data = [...arr, ...this.data]


        this.$nextTick(() => {
          $('.partyWork-list').height(this.data.length * $('.partyWork-item').outerHeight())
          $('.partyWork-list').stop().animate({ top: -($('.partyWork-item').outerHeight() * 2) }, 0)
        })

        this.imgUrl = this.data[4]  // 默认大图展示轮播中间的图
      }
    },
    topClick () {
      throttle(() => {
        this.changeHyrys(3)
      }, 500)
    },
    bottomClick () {
      throttle(() => {
        this.changeHyrys(5)
      }, 500)
    },

    // 切换数据时的效果逻辑
    changeHyrys (ind) {
      let itemDom = $('.partyWork-item')
      let itemDomHei = itemDom.outerHeight()
      let listDom = $('.partyWork-list')

      let topResults = -(itemDomHei * (ind - 2))

      listDom.stop().animate({ top: topResults }, 300)
      setTimeout(() => {
        // 重新排列数组
        if (ind > 4) {  // 点击的下标 > 中间的下标，截取数组头放到数组尾
          let arr = this.data.splice(0, Math.abs(ind - 4))
          this.data = [...this.data, ...arr]
        } else if (ind < 4) {// 点击的下标 < 中间的下标，截取数组尾放到数组头
          let arr = this.data.splice(ind - 4, Math.abs(ind - 4))
          this.data = [...arr, ...this.data]
        }
        this.imgUrl = this.data[4]
        // 回到原始位置
        listDom.stop().animate({ top: -(itemDomHei * 2) }, 0)
      }, 310);
    },
    // 大图展示
    previewImg (ind) {
      this.changeHyrys(ind)
    },

    // 获取新闻
    async getNews (type, page) {
      try {
        let newsRes = await getNews({ type, page })
        this.news = newsRes.data.data
        if (this.news.length) {
          this.nums = Math.ceil(newsRes.data.num / newsRes.data.page_size) // 总页数
        } else {
          this.nums = 0
        }
      } catch (error) {

      }
    },
    // 切换分页重新获取数据
    changePage (page) {
      this.getNews('branch-news', page)
    }
  },

};
</script>

<style scoped lang="less">
section {
  margin-bottom: 30px;
}
.partyWork-carousel {
  .partyWork-image {
    .partyWork-bigImg {
      min-width: 467px;
    }
    .partyWork-swiper {
      margin-left: 12px;
      height: 100%;
      width: 70px !important;
      overflow: hidden;
      position: relative;
      .swiper-button {
        width: 100%;
        height: 17px;
        background-color: #ffd27d;
        text-align: center;
        position: absolute;
        z-index: 2;
        &.button-next {
          bottom: 0;
        }
      }
      /deep/ .partyWork-list {
        margin: 16px 0;
        width: 70px !important;
        // height: 132%;
        position: absolute;
        .partyWork-item {
          width: 70px !important;
          // height: 12%;
          height: 52px;
          padding: 4px 0;
          background-color: #ffffff;
          // margin: 3px 0;
        }
      }
    }
  }
  .partyWork-name {
    text-align: center;
    flex: 1;
    p {
      font-size: 20px;
      font-weight: bold;
      margin-top: 24px;
    }
  }
}
/deep/ .partyWork-info {
  padding: 0 30px;
  strong {
    font-size: 24px;
    margin-bottom: 24px;
  }
  p {
    line-height: 30px;
  }
}
.partyWork-dynamic {
  padding: 30px;
  .dynamic-title {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .dynamic-list {
    .dynamic-item {
      margin: 12px 0;
      height: 16px;
      position: relative;
      a:hover {
        color: #e1a247;
        i {
          border-bottom: 1px dashed #e1a247;
        }
      }
      .dynamic-name {
        margin-left: 12px;
        position: absolute;
        width: 90%;
        span {
          max-width: 60%;
        }
        i {
          flex: 1;
          border-bottom: 1px dashed #919191;
          margin: 0 20px;
        }
      }
      .dynamic-time {
        position: absolute;
        right: 0;
      }
    }
  }
}

@media (max-width: 1299px) {
  .partyWork-dynamic {
    .dynamic-list {
      .dynamic-item {
        .dynamic-name {
          width: 89%;
        }
      }
    }
  }
}
</style>
